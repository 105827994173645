import React, { FC, useRef, useState } from 'react';
import './FieldCode.scss';
import classNames from 'classnames';

interface Props {
   length: number;
   label: string;
   isError?: boolean;
   disabled?: boolean;
   onChange?: (value: string) => void;
   onFocus?: () => void;
   onBlur?: () => void;
   value?: string;
   autoFocus?: boolean;
}
const specialKeys = new Array();

const InputCode: FC<Props> = (props) => {
   const { length, label, onChange, isError, value, autoFocus } = props;
   const initialValue = value ? value.split('') : [...Array(length)].map(() => '');
   const [code, setCode] = useState(initialValue);
   const [isFocus, setFocus] = useState(false);

   const inputs = useRef<(HTMLInputElement | null)[]>([]);

   const onFocus = (e, slot) => {
      setFocus(true);
      if( props.onFocus){
         props.onFocus()
      }
      if (inputs.current[slot] && inputs.current[slot].select) {
         inputs.current[slot].select();
      }
   };
   const onKeyPress = (e, slot) => {
      // const keyCode = e.which ? e.which : e.keyCode;
      // const ret = (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || specialKeys.indexOf(keyCode) != -1;
      const key = e.key >= 0 && e.key <= 9;
      // console.log("keyCode",keyCode);
      console.log("ret",e.key);

      if (key) {
         // const val = String.fromCharCode(e.keyCode);



         console.log('change', e.key);
         setCode(oldCode => {
            const newCode = [...oldCode];
            newCode[slot] = e.key;

            if (onChange) {
               onChange(newCode.join(''));
            }

            return newCode;
         });

         if (slot !== length - 1) {
            inputs.current[slot + 1].focus();
            inputs.current[slot + 1].select();
         }
      }

      if (e.keyCode === 8) {
         console.log('delete');
         //@ts-ignore
         setCode(oldCode => {
            const newCode = [...oldCode];
            newCode[slot] = '';

            if (onChange) {
               onChange(newCode.join(''));
            }
            return newCode;
         });

         if (slot !== 0) {
            inputs.current[slot - 1].focus();
            inputs.current[slot - 1].select();
         }

         // return false;
      }
   };
   const classes = classNames('mui-form-group', 'code-input__item', { isError });
   const parentClass = classNames('code-input', { isError }, { isFocus });

   return (
      <div className={parentClass}>
         <label className="code-label control-label">
            {label}
         </label>
         <div className="code-input__wrapper">
            {code.map((num, idx) => {
               return (
                  <div className={classes} key={idx}>
                     <input
                        type="text"
                        inputMode="numeric"
                        maxLength={1}
                        value={num}
                        autoFocus={!code[0].length && autoFocus && idx === 0}
                        onFocus={e => onFocus(e, idx)}
                        onBlur={() => {
                           setFocus(false);
                           if(props.onBlur){
                              props.onBlur()
                           }
                        }}
                        onKeyDown={e => onKeyPress(e, idx)}
                        ref={ref => inputs.current.push(ref)}
                     />
                     <i className="bar"></i>
                  </div>
               );
            })}
         </div>
      </div>
   );
};

export default InputCode;
