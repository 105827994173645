import React, { ChangeEvent, FC, HTMLProps } from 'react';
import {Field, FieldProps} from 'formik';
import ControlLayout from './ControlLayout/ControlLayout';
import MuiFieldInput from "../muiFields/MuiFieldInput";
import { removeSymbolsFromString } from '../../../../helpers/regEx';

interface Props extends HTMLProps<HTMLInputElement> {
  isError?: boolean;
  label?: string;
  mask?: string;
  maskChar?: string;
  alwaysShowMask?: boolean;
  formatChars?: any;
  unmask?: boolean;
  rightComponent?: React.ReactNode,
}

const AppFormInput: FC<Props> = props => {
  const {name, unmask = false, type = 'text', disabled, label, id, mask, alwaysShowMask, maskChar, readOnly, rightComponent, onChange} = props;

  return (
    <Field name={name}>
      {(propsField: FieldProps) => {
        const {field, meta, form} = propsField;
        const isError: boolean = meta.touched && !!meta.error;

        return (
          <ControlLayout isError={isError} error={meta.error}>
            <MuiFieldInput
              label={label}
              type={type}
              id={id}
              alwaysShowMask={alwaysShowMask}
              maskChar={maskChar}
              mask={mask}
              rightComponent={rightComponent}
              {...field}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {

                if(onChange) {
                  onChange(e)
                }

                if (unmask) {
                  const unmask = removeSymbolsFromString(e.target.value);
                  form.setFieldValue(name, unmask);

                } else {
                  form.setFieldValue(name, e.target.value);
                }
              }}
              readOnly={readOnly}
              isError={isError}
              disabled={disabled}
            />
          </ControlLayout>
        );
      }}
    </Field>
  );
};

export default AppFormInput;
