import React, { useCallback, useState } from 'react';
import AuthLayout from "../../Auth/common/AuthLayout/AuthLayout";
import AppButton from "../../../components/ui/components/Buttons/AppButton/AppButton";
import "./HotelRegistration.scss";
import hotelRegistrationApi from "../../../api/hotel-registration-api";
import { Form, Formik } from "formik";
import {
  codeSchema, newHotelRegistrationForm, selectRegistrationHotelForm,
} from "../../../helpers/validation";
import AppFormCode from "../../../components/ui/forms/controls/AppFormCode";
// @ts-ignore
import iconPhone from "./components/icons/phone.svg";
import AppFormInput from "../../../components/ui/forms/controls/AppFormInput";
import appToastr from "../../../services/appToastr";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FormikHelpers } from "formik/dist/types";
import AppLoader from "../../../components/ui/loaders/AppLoader/AppLoader";
import { EXTRANET_URL } from "../../../config";
import MuiRadio from "../../../components/ui/forms/muiFields/MuiRadio";
import FormCompletedHotelRegistration
  from "./components/forms/FormCompletedHotelRegistration/FormCompletedHotelRegistration";
import AppFormCheckboxGroup from "../../../components/ui/forms/controls/AppFormCheckboxGroup";
import AppFormRadio from "../../../components/ui/forms/controls/AppFormRadio";
import classNames from "classnames";
import NoFoundHotel from "./components/NoFoundHotel/NoFoundHotel";

interface CompleteData {
  phone: string,
  company_name: string,
  admin_name: string,
  admin_lastname: string,
  email: string,
  password: string,
  password_confirmation: string,
}

const HotelRegistrationInit = () => {
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState('');
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [showIndicator, setShowIndicator] = useState(false);
  const [showResendIndicator, setResendIndicator] = useState(false);
  const [secret, setSecret] = useState('');

  const [hotels, setHotels] = useState([]);

  const [authData, setAuthData] = useState({
    expires_in: '', token: '', type: ''
  });
  const { executeRecaptcha } = useGoogleReCaptcha();


  const sendCode = (data, formikHelpers) => {
    const { setErrors } = formikHelpers;
    setShowIndicator(true)

    hotelRegistrationApi.confirmCode({ ...data }).then((res) => { //selectedHotel.value,
      // console.log(res);
      setSecret(res.data?.secret || '')

      // setHotels([{ id: '1', name: 'Hotel name 1' }, { id: '2', name: 'Hotel name 2' }])
      setHotels(res.data?.hotels?.data || [])
      // console.log("res", res);
      setStep(3)
    }).catch((error) => {
      const { response } = error;

      if (response?.status === 406) {
        if (response?.data?.message) {
          setErrors({ code: response.data.message })
        }
      }

      if (response?.data?.message) {
        appToastr.error(response.data.message)
      }

      if (response?.data?.errors) {
        setErrors(response.data.errors)
      }
    }).finally(() => {
      setShowIndicator(false)
    })
  }

  const handleSubmitPhone = useCallback(async ({ phone }) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha();

    setPhone(phone)

    if (token) {
      setShowIndicator(true)
      hotelRegistrationApi.registerNew({
        phone,
        "g-recaptcha-response": token
      }).then((res) => {
        setStep(2)
        appToastr.success(`Код відправлено на ваш номер ${phone}`)
      }).catch((error) => {

        if (error.response?.status === 409) {
          appToastr.error(`Номер ${phone} вже зареєстрований`)
        }
      }).finally(() => {
        setShowIndicator(false)
      })
    }
  }, [executeRecaptcha, phone])


  const handleSubmitSelectedHotel = (data, formikHelper) => {
    const selected = hotels.find((item) => item.id == data.hotel_id)

    setSelectedHotel(selected);
    setStep(4)
  }

  // RESEND CODE WITH RECAPTCHA
  const onResend = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha();
    if (token) {

      setResendIndicator(true)
      hotelRegistrationApi.registerNew({
        phone: phone,
        "g-recaptcha-response": token
      }).then((res) => {
        appToastr.success(`Код відправлено на ваш номер ${phone}`)
      }).catch((error) => {

        if (error.response?.status === 409) {
          appToastr.error(`Номер ${phone} вже зареєстрований`)
        }
      }).finally(() => {
        setResendIndicator(false)
      })
    }
  }, [executeRecaptcha, phone])

  const submitCompetedData = (data: CompleteData, formikHelpers: FormikHelpers<CompleteData>) => {
    setShowIndicator(true)

    let requestData = { ...data, secret };

    hotelRegistrationApi.registrationCompleted(requestData).then((res) => { //selectedHotel.value,
      // show success page
      setStep(5)
      appToastr.success(`Готель було успішно зареєстровано`)
      setAuthData(res.data)

    }).catch((error) => {
      if (error?.response?.data?.message) {
        appToastr.error(error.response.data.message)
      } else {
        appToastr.error(`Не вдалось зареєструвати готель`)
      }

      if (error?.response?.data?.errors) {
        formikHelpers.setErrors(error.response.data.errors)
      }
    }).finally(() => {
      setShowIndicator(false)
    })
  }

  const goToExtranet = () => {
    const { expires_in, token, type } = authData;
    const url = `${EXTRANET_URL}?token=${token}&expires_in=${expires_in}&type=${type}`;
    window.open(url, '_blank')
  }

  const steps = [1, 2, 3];
  const title = 'Реєстрація доступу';
  const background = "/images/backgrounds/4.png";


  if (step === 3) {

    return (
      <AuthLayout image={background} activeStep={3}
                  steps={steps}
                  title={title}
                  className="hotel-registration-init"
                  AuthHeader={() => <></>}
      >

        {
          hotels.length ?
        <div className="registration-select-hotel">
          <h4>За вашим номером знайдено 3 готелі</h4>
          <h5>Виберіть один із них для керуванням та налаштуванням доступності або створіть новий </h5>

          <Formik
            initialValues={{ hotel_id: null, phone }}
            validationSchema={selectRegistrationHotelForm} onSubmit={handleSubmitSelectedHotel}
            enableReinitialize
          >
            <Form>
              <div className="hotel-registration-init__form">
                <div>
                  <AppFormInput disabled={true} name="phone" label={'Номер телефону'} unmask
                                mask="+38 \(999) 999 99 99"/>
                  {/* hotels */}
                  <AppFormRadio
                    name="hotel_id"
                    valueName="id"
                    keyName="name"
                    options={hotels}
                    RadioOptionTemplate={(option) => {
                      console.log(option);
                      const { data, isActive, isError } = option;
                      const classes = classNames('available-hotel-option', { isActive, isError })
                      return (
                        <div className={classes}>
                          <div className="available-hotel-option__main">
                            <img src={`${data.photo || '/images/backgrounds/no-image.svg'}`} alt=""/>
                            <span>{data.name}</span>
                          </div>
                          <MuiRadio checked={isActive}/>
                        </div>
                      )
                    }}
                  />
                </div>

                <div>
                  <AppButton theme="outline"
                             type="button"
                             style={{ width: '100%' }}
                             onClick={(e) => {

                               setStep(4)
                             }}
                             size="middle">
                    Створити новий готель
                  </AppButton>
                  <AppButton theme="primary"
                             type="submit"
                             className="hotel-registration-init__submit"
                             size="middle" showIndicator={showIndicator}>
                    Продовжити
                  </AppButton>
                </div>
              </div>
            </Form>
          </Formik>
          <br/>
        </div> :
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
              <NoFoundHotel searchPhone={phone}/>

              <AppButton theme="outline"
                         type="button"
                         style={{ width: '100%' }}
                         onClick={(e) => {
                           setStep(4)
                         }}
                         size="middle">
                Створити новий готель
              </AppButton>
            </div>
            }

      </AuthLayout>
    )
  }

  // форма вводу телефону
  if (step === 1) {
    return (
      <AuthLayout
        steps={steps}
        image={background}
        activeStep={1}
        title={title}

        className="hotel-registration-init"
        AuthHeader={() => <></>}
      >
        <Formik
          initialValues={{ phone: null }}
          validationSchema={newHotelRegistrationForm} onSubmit={handleSubmitPhone}
          enableReinitialize
        >
          <Form>
            <div className="hotel-registration-init__form">
              <AppFormInput name="phone" label={'Номер телефону рецепції готелю'} unmask
                            mask="+38 \(999) 999 99 99"/>
            </div>
            <AppButton theme="primary"
                       className="hotel-registration-init__submit"
                       size="middle" showIndicator={showIndicator}>
              Продовжити
            </AppButton>
          </Form>
        </Formik>

      </AuthLayout>)
  }

  // Підтвердження телефону за допомогою коду з смс
  if (step === 2) {
    return (
      <AuthLayout image={background} activeStep={2}
                  title="Реєстрація доступу"
                  steps={steps}
                  className="hotel-registration-init"
                  AuthHeader={() => <></>}
      >
        <Formik initialValues={{ code: '', phone }} validationSchema={codeSchema} onSubmit={sendCode}
                enableReinitialize>
          <Form>
            <div className="hotel-registration-init__form">
              <div>
                <AppFormInput disabled={true} name="phone" label={'Номер телефону рецепції готелю'} unmask mask="+38 \(999) 999 99 99"/>
                <AppFormCode name="code" label={'Введіть код, який ми надіслали через SMS'}/>
                <span className="resend-code-block">
                  Не отримали SMS-повідомлення?
                  <br/>
                  <span onClick={onResend}>Надіслати повторно {showResendIndicator && <AppLoader size={20}/>}</span>
               </span>
              </div>
            </div>
            <AppButton theme="primary"
                       className="hotel-registration-init__submit"
                       size="middle" showIndicator={showIndicator}>
              Продовжити
            </AppButton>
          </Form>
        </Formik>
      </AuthLayout>)
  }


  // Заповнення і реєстрація компанії
  if (step === 4) {

    return (
      <AuthLayout image={background} activeStep={3}
                  steps={steps}
                  title={title}
                  className="hotel-registration-init"
                  AuthHeader={() => <></>}
      >
        <FormCompletedHotelRegistration
          selectedHotel={selectedHotel}
          onSubmit={submitCompetedData}
          phone={phone}
          showIndicator={showIndicator}/>
      </AuthLayout>
    )
  }

  if (step === 5) {
    return (
      <AuthLayout image={background} activeStep={4}
                  steps={steps}
                  title={title}
                  className="hotel-registration-init"
                  AuthHeader={() => <></>}
      >
        <div className="hotel-registration-success">
          <div>
            <p>Ви успішно зареєстрували доступ для управління
              готелем <span> {selectedHotel && selectedHotel?.name}</span></p>
            <p>
              Тепер ви можете перейти до Екстранету для
              самостійного адміністрування опису та параметрів
              готелю, встановлення тарифів, квотування
              та самостійного опрацювання бронювань
            </p>
          </div>

        </div>
        <AppButton theme="primary"
                   onClick={goToExtranet}
                   className="hotel-registration-init__submit"
                   size="middle">
          Перейти до Екстранету
        </AppButton>
      </AuthLayout>
    )
  }

  return null
}

export default HotelRegistrationInit;
