import React from "react";
import "./FormCompletedHotelRegistration.scss";
import { registrationFormCompleteSchema } from "../../../../../../helpers/validation";
import { Form, Formik } from "formik";
import AppFormInput from "../../../../../../components/ui/forms/controls/AppFormInput";
import AppButton from "../../../../../../components/ui/components/Buttons/AppButton/AppButton";


const FormCompletedHotelRegistration = ({ onSubmit, phone, showIndicator, selectedHotel }) => {
  const initial = {
    hotel_name: selectedHotel?.name ? selectedHotel?.name : '',
    phone: phone,
    // company_name: '',
    admin_name: '',
    admin_lastname: '',
    email: '',
    password: '',
    password_confirmation: '',
  }

  if (selectedHotel) {
    //@ts-ignore
    initial.hotel_id = selectedHotel.id
    initial.hotel_name = selectedHotel.name
  }

  return (
    <Formik initialValues={initial}
            validationSchema={registrationFormCompleteSchema}
            onSubmit={onSubmit}
            enableReinitialize>
      <Form>
        <div>
          {selectedHotel && <AppFormInput label="" name="hotel_id" type="hidden"/>}
          <AppFormInput disabled={true} name="phone" label={'Номер телефону'} unmask mask="+38 \(999) 999 99 99"/>
          <AppFormInput label="Назва готелю" name="hotel_name"/>
          {/*<AppFormInput label="Назва компанії" name="company_name"/>*/}
          <AppFormInput label="Ім'я" name="admin_name"/>
          <AppFormInput label="Прізвище" name="admin_lastname"/>
          <AppFormInput label="Email" name="email"/>
          <AppFormInput label="Пароль" name="password" type="password"/>
          <AppFormInput label="Пароль" name="password_confirmation" type="password"/>
        </div>
        <AppButton theme="primary"
                   className="hotel-registration-init__submit"
                   size="middle" showIndicator={showIndicator}>
          Продовжити
        </AppButton>
      </Form>
    </Formik>
  )
}

export default FormCompletedHotelRegistration;