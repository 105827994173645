import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { phoneSchema } from '../../../../helpers/validation';
import AppButton from '../../../../components/ui/components/Buttons/AppButton/AppButton';
import AppFormInput from '../../../../components/ui/forms/controls/AppFormInput';
import { FormikHelpers } from 'formik/dist/types';

interface Values {
   login: string;
}

interface IProps {
   onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
   isSending: boolean;
}

const initialValues = {
   login: '',
};

const FormPhone: FC<IProps> = ({ onSubmit, children, isSending }) => {
   return (
      <Formik initialValues={initialValues} validationSchema={phoneSchema} onSubmit={onSubmit}>
         <Form>
            <div>
               <AppFormInput
                  name="login"
                  label={'Номер вашого телефону'}
                  alwaysShowMask={true}
                  mask="+38 \(999) 999 99 99"
                  unmask
               />
               <AppButton theme="primary" size="middle" className="sign-up__submit" showIndicator={isSending}>
                  Продовжити
               </AppButton>
            </div>
            {/*{children && <div style={{ alignSelf: 'flex-end' }}>{children}</div>}*/}
         </Form>
      </Formik>
   );
};

export default FormPhone;
