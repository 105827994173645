import React, { FC, HTMLProps } from 'react';
import {Field, FieldProps} from 'formik';
import ControlLayout from './ControlLayout/ControlLayout';
import FieldCode from '../muiFields/FieldCode/FieldCode';

interface Props extends HTMLProps<HTMLInputElement> {
   label?: string;
}

const AppFormCode: FC<Props> = props => {
   const {name, disabled, label, autoFocus} = props;

   return (
      <Field name={name}>
         {(propsField: FieldProps) => {
            const {field, meta, form} = propsField;
            const isError: boolean = meta.touched && !!meta.error;

            return (
               <ControlLayout isError={isError} error={meta.error}>
                  <FieldCode
                     length={4}
                     label={label}
                     {...field}
                     onFocus={() => {
                        // form.setFieldTouched(name, true);
                     }}
                     onBlur={()=>{
                        form.setFieldTouched(name, true);
                     }}
                     onChange={(value: string) => {
                        form.setFieldValue(name, value);
                     }}
                     autoFocus={autoFocus}
                     isError={isError}
                     disabled={disabled}
                  />
               </ControlLayout>
            );
         }}
      </Field>
   );
};

export default AppFormCode;
