import React, { useState } from 'react';
import './SignIn.scss';
import { Link, Redirect } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { signInThunk } from '../../../store/auth/auth-actions';
import { signInSchema } from '../../../helpers/validation';
import { authSelector, isAuthorizedSelector } from '../../../store/auth/auth-selector';
import AppFormInput from '../../../components/ui/forms/controls/AppFormInput';
import { SighInRequest } from '../../../types/auth';
import AppButton from '../../../components/ui/components/Buttons/AppButton/AppButton';
import AuthLayout from '../common/AuthLayout/AuthLayout';
import useInitial from '../../../hooks/useInitial';
import { unwrapResult } from '@reduxjs/toolkit';
import { FormikHelpers } from 'formik/dist/types';
import useQuery from '../../../hooks/useQuery';

const initialValues = {
    login: '',
    password: '',
};

const SignIn = () => {
    useInitial();
    let query = useQuery();
    const [showPassword, setShowPassword] = useState(false);

    const isAuthorized = useSelector(isAuthorizedSelector);
    const {isSending} = useSelector(authSelector);
    const dispatch = useDispatch();

    const handleSubmit = (values: SighInRequest, formikHelpers: FormikHelpers<SighInRequest>) => {
        const {setErrors} = formikHelpers;

        //@ts-ignore
        dispatch(signInThunk(values)).then(unwrapResult)
            .catch(error => {
                setErrors({
                    login: 'Невірний логін чи пароль',
                    password: 'Невірний логін чи пароль',
                });
            });
    };

    if (isAuthorized) {
        return <Redirect to={query.get('redirect-url') || '/profile'}/>;
    }

    const rightComponent = () => {
        if(showPassword){
            return <span className="sign-in-show" onClick={()=> setShowPassword(false)} >Приховати</span>
        } else {
            return <span className="sign-in-show" onClick={()=> setShowPassword(true)}>Показати</span>
        }
    }

    return (
        <AuthLayout
            image="/images/backgrounds/3.jpg"
            activeStep={1}
            className="sign-in"
            title="З поверненням!"
            AuthHeader={() => (
                <>
                    Не зареєстровані? <Link to="/auth/signup">Зареєструйтесь</Link>
                </>
            )}
        >
            <Formik initialValues={initialValues} validationSchema={signInSchema} onSubmit={handleSubmit}>
                <Form>
                    <div>
                        <AppFormInput

                          name="login" label={'Номер вашого телефону'} unmask mask="+38 \(999) 999 99 99"/>
                        <AppFormInput rightComponent={rightComponent()} type={showPassword ? "text": "password"} name="password" label={'Пароль'}/>
                        <AppButton theme="primary" className="sign-in__submit" size="middle" showIndicator={isSending}>
                            Увійти
                        </AppButton>
                        <Link to="/auth/reset" className="sign-in__remainder-link">
                            Забули пароль?
                        </Link>
                    </div>
                </Form>
            </Formik>
            <div>
                {/*<FormSocialGroup/>*/}

                {/*<div className="sign-in__sign-up-link-mobile">*/}
                {/*   <AuthSignUpLink/>*/}
                {/*</div>*/}
            </div>
        </AuthLayout>
    );
};
export default SignIn;
