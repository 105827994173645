import React from 'react';
import './HotelDetailPhoneOrder.scss';
import HotelCallbackBlock from "../HotelCallbackBlock/HotelCallbackBlock";

const HotelDetailPhoneOrder= () => {

  return (
    <HotelCallbackBlock />
  );
};

export default HotelDetailPhoneOrder;
